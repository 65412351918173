import React from 'react'

function IconStatsCycle() {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height={40}
                fill="none"
            >
                <rect width={40} height={40} fill="#83378A" rx={10} />
                <path
                    fill="#fff"
                    d="M27.423 12.907C25.83 11.2 23.615 10.103 21.158 10c-.617-.026-1.137.469-1.162 1.104-.025.636.455 1.172 1.071 1.198 1.864.078 3.533.905 4.745 2.201a7.286 7.286 0 0 1 1.952 4.992c0 1.992-.78 3.785-2.046 5.091-1.268 1.305-3.008 2.108-4.94 2.109-1.933 0-3.673-.804-4.94-2.109a7.285 7.285 0 0 1-2.035-5.508l1.332.677a.48.48 0 0 0 .443.004.393.393 0 0 0 .206-.358l-.132-6.032a.5.5 0 0 0-.277-.42.468.468 0 0 0-.492.029l-4.726 3.56a.394.394 0 0 0-.151.386.502.502 0 0 0 .272.361l1.395.71c-.075.49-.118.99-.118 1.5a9.627 9.627 0 0 0 2.702 6.72C15.922 27.934 18.234 29 20.777 29c2.543 0 4.856-1.066 6.522-2.783A9.628 9.628 0 0 0 30 19.497a9.63 9.63 0 0 0-2.577-6.59Z"
                />
            </svg>
        </>
    )
}

export default IconStatsCycle