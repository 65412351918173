import React from "react";

import useTrans from "hooks/use-trans";
import useRouter from "hooks/use-router";
import { useDispatch, useSelector } from "react-redux";

import Button from "shared/components/form-input/button/Button";
import classes from "./InquiryDetailPopup.module.scss";
import IconClose from "assets/icon/IconClose";
import InquiryAction from "redux/inquiry/action";
import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_UPDATE_INQUIRY_POPUP,
    EVENT_SHOW_INQUIRY_DETAIL_POPUP,
    EVENT_SHOW_POPUP_CONFIRM_DELETE,
} from "utils/EventRegister";

import RowItem from "./component/RowItem";
import Section from "./component/Section";
import Constants from "utils/Constants";
import Permission from "utils/Permission";

const InquiryDetailPopup = (props) => {
    const { showVisible } = props;
    const { t } = useTrans();
    const dispatch = useDispatch();
    const router = useRouter();

    const {
        inquiryDetail,
        loading: { loadingDeleteInquiry,
            loadingCreateUserInquiry },
    } = useSelector((state) => state.inquiry);

    const fakeInquiryData = [
        {
            name: "inquiryCode",
            label: t("inquiry.inquiry_code"),
        },
        {
            name: "typeName",
            label: t("type"),
        },
        {
            name: "companyName",
            label: t("companyName"),
        },
        {
            name: "departmentName",
            label: t("departmentName"),
        },
        {
            name: "fullName",
            label: t("fullName"),
        },
        {
            name: "fullNameFuri",
            label: t("Inquiry.nameFurigana"),
        },
        {
            name: "phone",
            label: t("phone"),
        },
        {
            name: "email",
            label: t("Inquiry.email"),
        },
        {
            name: "topic",
            label: t("inquiry.inquiry_topic"),
        },
        {
            name: "content",
            label: t("inquiry.inquiry_content"),
        },
    ];

    const onCreate = (id) => {
        dispatch({
            type: InquiryAction.GET_INQUIRY_DETAIL,
            payload: id,
        });
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_INQUIRY_DETAIL_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    };

    const createAccountUser = (id) => {
        const idCreateUser = {
            id: id
        }
        dispatch({
            type: InquiryAction.CREATE_USER,
            payload: idCreateUser,
            onSuccess: onCreate,
            onFailure: onCreate,
        });
    }

    const openUpdatePopup = () => {
        // TODO
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            type: EVENT_SHOW_UPDATE_INQUIRY_POPUP,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                callback: () => { },
            },
        });
    };

    const handleClose = () => {
        // TODO
        showVisible();
        dispatch({
            type: InquiryAction.GET_INQUIRY_DETAIL_SUCCESS,
            payload: {},
        });
    };

    const onDeleteInquirySuccess = () => {
        dispatch({
            type: InquiryAction.GET_INQUIRY_LIST,
            payload: {
                ...router.getAll(),
            },
        });
        showVisible(false);
    };

    const deleteInquiry = () => {
        dispatch({
            type: InquiryAction.DELETE_INQUIRY,
            payload: inquiryDetail.id,
            onSuccess: onDeleteInquirySuccess,
        });
    };

    const handleDelete = () => {
        if (inquiryDetail?.id) {
            EventRegister.emit(EVENT_SHOW_POPUP, {
                open: true,
                newWindow: true,
                type: EVENT_SHOW_POPUP_CONFIRM_DELETE,
                payload: {
                    title: t("Holon_Template.confirm_delete"),
                    showHeader: false,
                    customStyle: {
                        padding: 0,
                        borderTop: 0,
                    },
                    callback: deleteInquiry,
                },
            });
        }
    };

    const renderHeaderPopup = () => {
        return (
            <div className={classes["Header"]}>
                <div className={classes["HeaderLeft"]}>
                    <div className={classes["Icon"]}>
                        {/* <Button>
                            <IconLeftArrow />
                        </Button> */}
                    </div>
                    <div className={classes["PopupTitle"]}>
                        <p>{t("inquiry.inquiry_detail")}</p>
                    </div>
                </div>
                <div className={classes["HeaderRight"]}>
                    <Button
                        onClick={() => handleClose()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#83378A"
                            />
                        }
                    />
                </div>
            </div>
        );
    };

    const renderInquiryDetail = () => {
        return fakeInquiryData.map((obj, idx) => {
            const labelName = obj.label;
            let inputValue = inquiryDetail[obj.name] ?? "";
            if (
                ["companyName", "departmentName"].includes(obj.name) &&
                inquiryDetail.type !==
                Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE.toString()
            ) {
                return;
            }
            return (
                <RowItem
                    key={idx}
                    label={labelName}
                    value={inputValue}
                />
            );
        });
    };

    const renderContentPopup = () => {
        return (
            <div className={classes["Body"]}>
                <Section sectionTitle={t("inquiry.contact_information")}>
                    {renderInquiryDetail("detail")}
                </Section>
                {/* {
                    Permission.IsEnabledFunction(Permission.FUNCTIONS.UPDATE_MANAGEMENT_INFO_INQUIRY) && */}
                <Section sectionTitle={t("inquiry.response_for_management")}>
                    <RowItem
                        label={t("inquiry.chosen_pic_sale")}
                        value={inquiryDetail?.saleName}
                    />
                    <RowItem
                        label={t("inquiry.chosen_pic_manager")}
                        value={inquiryDetail?.managerName}
                    />
                    <RowItem
                        label={t("inquiry.create_account")}
                        valueWithCondition={inquiryDetail?.hasAccount != 1 ?
                            <Button
                                onClick={() => createAccountUser(inquiryDetail?.id)}
                                type="button"
                                title={t("inquiry.btn_create")}
                                loading={loadingCreateUserInquiry}
                            // disabled={!inquiryDetail.id}
                            />
                            :
                            <div className={classes["InsideValue"]}>
                                <p>
                                    {t("inquiry.issued")}
                                </p>
                            </div>
                        }
                    />
                    <RowItem
                        label={t("inquiry.is_need_estimate")}
                        value={inquiryDetail?.needEstimate ===
                            Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE
                            ? t("inquiry.can")
                            : t("inquiry.can_not")}
                    />
                    <RowItem
                        label={t("inquiry.estimate_no")}
                        value={inquiryDetail?.estimateCode}
                    />
                    <RowItem
                        label={t("remark")}
                        value={inquiryDetail?.remark}
                    />
                    <RowItem
                        label={t("created_by")}
                        value={inquiryDetail?.nameCreateBy}
                    />
                    <RowItem
                        label={t("updated_by")}
                        value={inquiryDetail?.nameUpdatedBy}
                    />
                </Section>
                {/* } */}
            </div>
        );
    };

    const renderContentFooter = () => {
        return (
            <div className={classes["Footer"]}>
                {/* {
                    Permission.IsEnabledFunction(Permission.FUNCTIONS.UPDATE_INQUIRY) && */}
                <Button
                    onClick={openUpdatePopup}
                    type="button"
                    title={t("inquiry.edit")}
                // loading={loadingDeleteInquiry}
                // disabled={!inquiryDetail.id}
                />
                {/* } */}
            </div>
        );
    };

    return (
        <div className={classes["InquiryDetailPopup"]}>
            {renderHeaderPopup()}
            <div className={classes['InquiryDetailPopupContent']}>
                {renderContentPopup()}
            </div>
            {renderContentFooter()}
        </div>
    );
};

export default InquiryDetailPopup;
