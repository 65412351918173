import React from 'react'

function IconStatsPack() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            fill="none"
        >
            <rect width={40} height={40} fill="#83378A" rx={10} />
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
                d="M17.583 10.826a.611.611 0 0 1 .775.157L20 13.07l1.642-2.085a.606.606 0 0 1 .774-.158h.001l7.144 3.94a.61.61 0 0 1 .303.411m-12.28-4.35 12.162 4.849a.606.606 0 0 0 .118-.499m-12.28-4.35-7.145 3.94a.609.609 0 0 0-.184.91l1.567 1.99-2.626.74a.609.609 0 0 0-.13 1.12l3.295 1.817v4.421c0 .222.121.426.316.533l7.027 3.876a.608.608 0 0 0 .591 0l7.028-3.875a.61.61 0 0 0 .316-.534v-4.42l3.295-1.818-13.35-8.7Zm12.28 4.35-.245.051.245-.051Zm-1.686 2.491 2.626.739c.24.067.414.27.442.517l-.248.028-2.82-1.284Zm0 0 1.568-1.992-1.568 1.992ZM13.642 25.37v-3.593l2.834 1.563.09.05.099-.028 2.815-.792v6.02l-5.838-3.22Zm9.881-2.03 2.835-1.563v3.593l-5.838 3.22v-6.02l2.815.792.099.028.09-.05Zm3.22-5.918-5.956-3.284 1.51-1.917 5.955 3.284-1.51 1.917Zm-13.486 0-1.509-1.917 5.955-3.284 1.51 1.917-5.956 3.284Zm3.438 4.852-5.581-3.078 1.943-.547 5.583 3.078-1.945.547ZM20 14.891l5.802 3.2L20 21.29l-5.802-3.2L20 14.89Zm8.887 4.305-5.583 3.078-1.944-.547 5.582-3.078 1.945.547Z"
            />
        </svg>
    )
}

export default IconStatsPack