import React, { useEffect, useState } from "react";
import useTrans from "hooks/use-trans";
import classes from "./CrossBorderCourierService.module.scss";
import Button from "shared/components/form-input/button/Button";
import IconClose from "assets/icon/IconClose";
import FormEditor from 'shared/components/custom-form/FormEditor';
import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import { useForm } from "react-hook-form";
import { useFieldArray } from 'react-hook-form';
import IconSave from 'assets/icon/IconSave';
import ConstantsTable from "./ConstantTable";
import TrialCalculationItem from "./TrialCalculationItem";
import FormInput from "shared/components/custom-form/FormInput";
import { useDispatch, useSelector } from 'react-redux';
import actionFareCalculation from 'redux/freight-calculation/action';
import FormRadio from "shared/components/custom-form/FormRadio";
import Utils, { showPopupNotification } from "utils/Utils";
import Constants from "utils/Constants";
import Validator from "utils/Validator";

const CrossBorderCourierService = (props) => {
    const { showVisible, payload } = props;
    const { t } = useTrans();
    const methods = useForm();
    const control = methods.control;
    const dispatch = useDispatch();

    const {
        fareCalculationServiceCrossBorder,
    } = useSelector((state) => state.fareCalculation);

    const [fareCalculation, setFareCalculation] = useState(fareCalculationServiceCrossBorder)

    const handleClose = () => {
        showVisible();
    };

    useEffect(() => {
        setFareCalculation(fareCalculationServiceCrossBorder)
    }, [fareCalculationServiceCrossBorder])

    const {
        append: appendFareCalcConstants,
        fields: fieldsFareCalcConstants,
        remove: removeFareCalcConstants,
    } = useFieldArray({
        control, name: "fareCalcConstants"
    })

    const {
        append: appendFareCalcFormulas,
        fields: fieldsFareCalcFormulas,
        remove: removeFareCalcFormulas,
    } = useFieldArray({
        control, name: "fareCalcFormulas"
    })

    useEffect(() => {
        let fareCalcConstants = fareCalculation?.data?.fareCalcConstants?.map(x => {
            return {
                ...x,
            }
        })
        let fareCalcFormulas = fareCalculation?.data?.fareCalcFormulas?.map(x => {
            return {
                ...x,
            }
        })
        fareCalculation.fareCalcFormulas = fareCalcFormulas;
        fareCalculation.fareCalcConstants = fareCalcConstants;
        methods.reset(fareCalculation)
        methods.setValue('name', fareCalculation?.data?.name)
        methods.setValue('isCalculationObject', fareCalculation?.data?.isCalculationObject ?? Constants.CROSS_BORDER_COURIER?.YES?.VALUE)
        methods.setValue('content', fareCalculation?.data?.content)
        return () => {
            methods.reset({})
        }
    }, [fareCalculation])

    const onSubmit = (data) => {
        const updateData = {
            ...data,
            fareCalcFormulas: data?.fareCalcFormulas ?? [],
            fareCalcConstants: data?.fareCalcConstants ?? [],
            id: fareCalculation?.data?.id ?? '',
            isCalculationObject: data?.isCalculationObject,
            remark: null
        };
        delete updateData?.data;
        if (fareCalculation?.data?.id) {
            updateService(updateData)
        } else {
            createService(updateData)
        }
    }

    const updateService = (data) => {
        dispatch({
            type: actionFareCalculation.UPDATE_FARE_DETAIL_MANAGEMENT_SERVICE,
            payload: data,
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        });
        payload?.callback && payload?.callback();
        showVisible(false);
    }

    const createService = (data) => {
        dispatch({
            type: actionFareCalculation.CREATE_FARE_DETAIL_MANAGEMENT_SERVICE,
            payload: data,
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        });
        payload?.callback && payload?.callback();
        showVisible(false);
    }

    const onUpdateSuccess = () => {
        showPopupNotification({
            typePopup: 'message',
            newWindow: true,
            message: t("E0046")
        })
        getFareCalculation()

    }

    const onUpdateFailure = (detail = {}) => {
        Object.keys(detail).forEach(key => {
            detail[key]?.map(msg => {
                showPopupNotification({
                    typePopup: 'message',
                    newWindow: true,
                    message: msg
                })
            })
        })
        getFareCalculation()

    }

    const crossBorder = Utils.convertObjectKeyToArr(
        Constants.CROSS_BORDER_COURIER
    );

    const getFareCalculation = () => {
        dispatch({
            type: actionFareCalculation.GET_CALCULATION_SERVICE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_GLOBAL_VARIABLE,
        })
        dispatch({
            type: actionFareCalculation.GET_FARE_CALCULATION_USER_INPUT,
        })
    }

    const renderHeaderPopup = () => {
        return (
            <div className={classes["Header"]}>
                <div className={classes["HeaderLeft"]}>
                    <div className={classes["Icon"]}>
                    </div>
                    <div className={classes["PopupTitle"]}>
                        <p>{fareCalculation?.data?.id ? t("fare_calculation.update_cross_border") : t("fare_calculation.new_cross_border")}</p>
                    </div>
                </div>
                <div className={classes["HeaderRight"]}>
                    <Button
                        onClick={() => handleClose()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#83378A"
                            />
                        }
                    />
                </div>
            </div>
        );
    };

    const renderContentPopup = () => {
        return (
            <div className={classes['contentPopup']}>
                <div className={classes["service-name"]}>
                    <p className={classes['title-service-name']}>
                        {t('fare_calculation.service_name')}
                    </p>
                    <div className={classes["service-name-select"]}>
                        <FormInput fieldName={"name"} validationFE={true} />
                    </div>
                </div>
                <div className={classes["line-estimate"]}></div>
                <div className={classes["service-name"]}>
                    <p className={classes['title-service-name']}>
                        {t('fare_calculation.estimated_target')}
                    </p>
                    <div className={classes["radio-service"]}>
                        <FormRadio
                            fieldName="isCalculationObject"
                            options={crossBorder}
                            validate={[Validator.required]}
                        />
                    </div>
                </div>
                <div className={classes["line-estimate"]}></div>
                <div className={classes['title-editor']}>
                    <p>
                        {t('fare_calculation.service_content')}
                    </p>
                </div>
                <FormEditor height='300px' fieldName="content" />
                <div className={classes["line-estimate"]}></div>
                <ConstantsTable control={control} fieldsFareCalcConstants={fieldsFareCalcConstants} removeFareCalcConstants={removeFareCalcConstants} appendFareCalcConstants={appendFareCalcConstants} />
                <div className={classes["line-estimate"]}></div>
                <TrialCalculationItem appendFareCalcFormulas={appendFareCalcFormulas} fieldsFareCalcFormulas={fieldsFareCalcFormulas} removeFareCalcFormulas={removeFareCalcFormulas} />
                <div className={classes["btn-form"]}>
                    <button onClick={() => { handleClose() }} type="btn" className={classes['btn-cancel']}>{t('Holon_Template.cancel')}</button>
                    <button className={classes['btn-submit']} type="submit"><div>
                        {<IconSave />}{t("Holon_Template.save")}</div></button>
                </div>
            </div>
        )
    }

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={classes["CrossBorderCourierService"]}>
                    {renderHeaderPopup()}
                    <div className={classes["CrossBorderCourierServiceContent"]}>
                        {renderContentPopup()}
                    </div>
                </div>
            </form>
        </CustomFormProvider>
    );
};

export default CrossBorderCourierService;
