import React, { useEffect, useState } from "react";

import classes from "./CreateInquiryPopup.module.scss";

import useTrans from "hooks/use-trans";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useRouter from "hooks/use-router";

import CustomFormProvider from "shared/components/custom-form/CustomFormProvider";
import InquiryAction from "redux/inquiry/action";
import UserAction from "redux/user/action";
import InquiryMapper from "redux/inquiry/mapper";
import FormRadio from "shared/components/custom-form/FormRadio";

import Utils, { showPopupNotification } from "utils/Utils";
import Constants from "utils/Constants";
import Button from "shared/components/form-input/button/Button";
import IconClose from "assets/icon/IconClose";

import Validator from "utils/Validator";
import RowInput from "./component/RowInput";
import Section from "./component/Section";

import EventRegister, {
    EVENT_SHOW_POPUP,
    EVENT_SHOW_POPUP_CONFIRM,
    EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
} from "utils/EventRegister";
import Permission from "utils/Permission";

const CreateInquiryPopup = (props) => {
    const { showVisible } = props;
    const { t } = useTrans();
    const methods = useForm();
    const router = useRouter();
    const [isIndividualCustomer, setIsIndividualCustomer] = useState(Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE);
    const [isNeedEstimate, setIsNeedEstimate] = useState(false);
    const {
        loading: { loadingUpsertInquiry },
    } = useSelector((state) => state.inquiry);

    const { saleList, managerList } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const userTypeOptions = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_USER_TYPE
    );

    const yesNoOptions = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_YES_NO_CHECKBOX
    );

    const temporaryAccount = Utils.convertObjectKeyToArr(
        Constants.ISSUANCE_TEMPORARY_ACCOUNT_INQUIRER
    );

    const inquiryStatusList = Utils.convertObjectKeyToArr(
        Constants.INQUIRY_PROCESS_STATUS
    );

    useEffect(() => {
        methods.setValue("userType", 1);
        methods.setValue("needEstimate", 0);
        methods.setValue("isCreateAccount", 0);
        //
        dispatch({
            type: UserAction.GET_SALE_LIST,
        });
        //
        dispatch({
            type: UserAction.GET_MANAGER_LIST,
        });
    }, []);

    const handleClose = () => {
        // TODO
        showVisible();
    };

    const onCreateSuccess = () => {
        showPopupNotification({
            typePopup: 'message',
            message: t("E0041")
        })
        dispatch({
            type: InquiryAction.GET_INQUIRY_LIST,
            payload: {
                ...router.getAll(),
            },
        });
    };

    const onCreateFailure = (detailError) => {
        let messageError = "";

        Object.keys(detailError).forEach((key) => {
            detailError[key]?.map((msg) => {
                messageError += msg;
            });
        });

        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE,
            payload: {
                title: messageError,
                newWindow: true,
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
            },
        });
    };

    const onSubmit = (formData) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            open: true,
            newWindow: true,
            type: EVENT_SHOW_POPUP_CONFIRM,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0,
                },
                message: t("E0040"),
                callback: () => {
                    dispatch({
                        type: InquiryAction.INSERT_INQUIRY,
                        payload: formData,
                        onSuccess: onCreateSuccess,
                        onFailure: onCreateFailure,
                    });
                }
            },
        });

    };

    const onChangeUserTypeRadio = (value) => {
        setIsIndividualCustomer(value);
        setIsNeedEstimate(false)
        if (isIndividualCustomer != value) {
            methods.reset({
                userType: value,
                isCreateAccount: 0,
                needEstimate: 0
            })
        }
    };

    const onChangeEstimateRadio = (value) => {
        setIsNeedEstimate(
            value === Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE
        );
        if (!(value === Constants.INQUIRY_YES_NO_CHECKBOX.YES.VALUE)) {
            methods.resetField("estimateCode")
        }
    };

    const renderHeaderPopup = () => {
        return (
            <div className={classes["Header"]}>
                <div className={classes["HeaderLeft"]}>
                    {/* <div className={classes["Icon"]}>
                        <Button>
                            <IconLeftArrow />
                        </Button>
                    </div> */}
                    <div className={classes["PopupTitle"]}>
                        <p>{t("inquiry.create_inquiry")}</p>
                    </div>
                </div>
                <div className={classes["HeaderRight"]}>
                    <Button
                        onClick={() => handleClose()}
                        color="gray"
                        startIcon={
                            <IconClose
                                fontSize={14}
                                color="#83378A"
                            />
                        }
                    />
                </div>
            </div>
        );
    };

    const renderContentPopup = () => {
        return (
            <div className={classes["Body"]}>
                <Section sectionTitle={t("inquiry.inquiry_detail")}>
                    <RowInput
                        label={t("type")}
                        inputType="radio"
                        fieldName="userType"
                        options={userTypeOptions}
                        onChangeValue={onChangeUserTypeRadio}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_status")}
                        inputType="select"
                        fieldName="status"
                        options={inquiryStatusList}
                        validate={[Validator.required]}
                    />
                    {isIndividualCustomer == Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE && (
                        <RowInput
                            label={t("inquiry.companyName")}
                            inputType="text"
                            fieldName="companyName"
                            maxLength={100}
                            validate={[Validator.required]}
                        />
                    )}
                    {isIndividualCustomer == Constants.INQUIRY_USER_TYPE.CORPORATION.VALUE && (
                        <RowInput
                            label={t("departmentName")}
                            inputType="text"
                            fieldName="departmentName"
                            maxLength={255}
                        />
                    )}
                    <RowInput
                        label={t("fullName")}
                        inputType="text"
                        fieldName="fullName"
                        maxLength={100}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("fullNameFuri")}
                        inputType="text"
                        fieldName="fullNameFuri"
                        maxLength={100}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("phone")}
                        inputType="text"
                        fieldName="phone"
                        maxLength={10}
                    />
                    <RowInput
                        label={t("Inquiry.email")}
                        inputType="text"
                        fieldName="email"
                        maxLength={50}
                        validate={[Validator.required, Validator.email]}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_topic")}
                        inputType="text"
                        fieldName="title"
                        maxLength={255}
                        validate={[Validator.required]}
                    />
                    <RowInput
                        label={t("inquiry.inquiry_content")}
                        inputType="textarea"
                        fieldName="content"
                        maxLength={1000}
                        validate={[Validator.required]}
                    />
                </Section>
                {/* {
                    Permission.IsEnabledFunction(Permission.FUNCTIONS.UPDATE_MANAGEMENT_INFO_INQUIRY) && */}
                <Section sectionTitle={t("inquiry.inquiry_process_management")}>
                    <RowInput
                        label={t("inquiry.chosen_pic_sale")}
                        inputType="select"
                        fieldName="chosenPICSale"
                        options={InquiryMapper.handleOption(
                            saleList?.records || []
                        )}
                    />
                    <RowInput
                        label={t("inquiry.chosen_pic_manager")}
                        inputType="select"
                        fieldName="chosenPICManager"
                        options={InquiryMapper.handleOption(
                            managerList?.records || []
                        )}
                    />
                    <div className={classes["RowInput"]}>
                        <p className={classes["Label"]}>
                            {t("inquiry.create_account")}
                        </p>
                        <FormRadio
                            fieldName="isCreateAccount"
                            options={temporaryAccount}
                        // onChangeValue={onChangeTemporaryAccount}
                        />
                    </div>
                    <div className={classes["RowInput"]}>
                        <p className={classes["Label"]}>
                            {t("inquiry.is_need_estimate")}
                        </p>
                        <FormRadio
                            fieldName="needEstimate"
                            options={yesNoOptions}
                            onChangeValue={onChangeEstimateRadio}
                        />
                    </div>
                    <RowInput
                        label={t("inquiry.estimate_no")}
                        inputType="text"
                        fieldName="estimateCode"
                        maxLength={15}
                        disabled={!isNeedEstimate}
                        validate={[isNeedEstimate ? Validator.required : () => { }]}
                    />
                    <RowInput
                        label={t("remark")}
                        inputType="textarea"
                        fieldName="remark"
                        maxLength={1000}
                    />
                </Section>
                {/* } */}
            </div>
        );
    };

    const renderContentFooter = () => {
        return (
            <div className={classes["footerCreate"]}>
                <Button
                    title={t("message.cancel")}
                    color={'white'}
                    onClick={() => handleClose()}
                />
                <Button
                    type="submit"
                    title={t("Holon_Template.save")}
                    loading={loadingUpsertInquiry}
                />
            </div>
        );
    };

    return (
        <CustomFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={classes["CreateInquiryPopup"]}>
                    {renderHeaderPopup()}
                    <div className={classes['CreateInquiryPopupContent']}>
                        {renderContentPopup()}
                    </div>
                    {renderContentFooter()}
                </div>
            </form>
        </CustomFormProvider>
    );
};

export default CreateInquiryPopup;
