import useTrans from 'hooks/use-trans';
import React, { useEffect, useState } from 'react'
import Box from 'shared/components/common/box/Box'
import BoxBody from 'shared/components/common/box/BoxBody'
import classes from './PackageTracking.module.scss';
import BoxLabel from 'shared/components/common/box/BoxLabel';
import BoxRow from 'shared/components/common/box/BoxRow';
import BoxContent from 'shared/components/common/box/BoxContent';
import FormInput from 'shared/components/custom-form/FormInput';
import CustomFormProvider from 'shared/components/custom-form/CustomFormProvider';
import BoxContentPopup from 'shared/components/common/popup/custom-body/BoxContentPopup';
import WrapContentBody from 'shared/components/common/popup/custom-body/WrapContentBody';
import Button from 'shared/components/form-input/button/Button';
import { useForm } from 'react-hook-form';
import Validator from 'utils/Validator';
import FormCaptcha from 'shared/components/custom-form/FormCaptcha';
import PackageTrackingSearch from './PackageTrackingSearch';
import { useDispatch } from 'react-redux';
import { actionsHolonInstance } from 'redux/holon-instance/action';
import { showPopupNotification } from 'utils/Utils';
import { G0100 } from 'utils/EventRegister';
import IconClose from 'assets/icon/IconClose';

function PackageTracking(props) {
    const { t } = useTrans();
    const methods = useForm();
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataRes, setDataRes] = useState(null);
    const [loading, setLoading] = useState(false);
    const watchValue = methods.watch([
        "item_1",
        "item_2",
        "item_3",
        "item_4",
        "item_5",
    ])
    const onSubmit = (data) => {
        setLoading(true);
        let arr = [];
        Object.keys(data).map(key => {
            if (key?.indexOf("item_") != -1 && data[key]) {
                arr.push({
                    item: data[key]
                })
            }
        });

        let payload = arr?.map(x => x?.item)?.join(",");
        dispatch({
            type: actionsHolonInstance.GET_PACKAGE_TRACKING,
            payload: payload,
            callback: {
                success: (res) => {
                    setDataSearch(arr);
                    setDataRes(res);
                    setStep(2);
                    setLoading(false)
                },
                failed: (msg) => {
                    showPopupNotification({
                        message: msg,
                        typePopup: 'message',
                        newWindow: true
                    });
                    setLoading(false)
                }
            }
        })
    }

    const onClear = () => {
        methods.reset({})
    }

    const handleBtnBack = () => {
        if (props?.data?.step == G0100) {
            props?.showVisible(false)
        } else {
            setStep(1);
            methods.reset({});
            setDataSearch([]);
            if (props?.data?.dataBody) {
                let data = Object.keys(props?.data?.dataBody).map(key => {
                    return {
                        value: props?.data?.dataBody[key]
                    }
                });
                data?.map((x, index) => {
                    methods.setValue(`item_${index + 1}`, x?.value)
                })
            }
        }
    }


    useEffect(() => {
        if (props?.data?.step == G0100) {
            setDataSearch(props?.data?.list);
            setDataRes(props?.data?.res);
            setStep(2);
        } else {
            if (props?.data?.dataBody) {
                let data = Object.keys(props?.data?.dataBody).map(key => {
                    return {
                        value: props?.data?.dataBody[key]
                    }
                });
                data?.map((x, index) => {
                    methods.setValue(`item_${index + 1}`, x?.value)
                })
            }
        }
    }, [props?.data?.dataBody])

    let dataInput = watchValue[0] || watchValue[1] || watchValue[2] || watchValue[3] || watchValue[4] ? false : true
    return (
        <>
            {
                step == 1 ? <CustomFormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <BoxContentPopup>
                            <WrapContentBody
                                actionSectionTop={
                                    <Button
                                        onClick={() => props?.showVisible && props?.showVisible()}
                                        color="gray"
                                        startIcon={
                                            <IconClose
                                                fontSize={14}
                                                color="#83378A"
                                            />
                                        }
                                    />
                                }
                                actionSectionBottom={<div className={classes['gap_20']}>
                                    <Button disabled={loading} onClick={() => onClear()} title={t("package_tracking.clear")} />
                                    <Button
                                        disabled={dataInput}
                                        loading={loading} type='submit' title={t("package_tracking.search")} /></div>}
                                showVisible={props?.showVisible} handleBtnBack={() => props?.showVisible(false)}
                                title={props?.title}
                                useForm={false}
                            >
                                <Box>
                                    <BoxBody style={{
                                        borderRadius: '10px'
                                    }}>
                                        <BoxLabel minWidth="100%">
                                            {t("package_tracking.enter_your_hex_order_number")}
                                        </BoxLabel>
                                        <BoxRow>
                                            <BoxLabel minWidth="70px">
                                                {Validator.renderMessage(t("package_tracking.item"), {
                                                    0: 1
                                                })}
                                            </BoxLabel>
                                            <BoxContent>
                                                <FormInput fieldName='item_1' />
                                            </BoxContent>
                                        </BoxRow>
                                        <BoxRow>
                                            <BoxLabel minWidth="70px">
                                                {Validator.renderMessage(t("package_tracking.item"), {
                                                    0: 2
                                                })}
                                            </BoxLabel>
                                            <BoxContent>
                                                <FormInput fieldName='item_2' />
                                            </BoxContent>
                                        </BoxRow>
                                        <BoxRow>
                                            <BoxLabel minWidth="70px">
                                                {Validator.renderMessage(t("package_tracking.item"), {
                                                    0: 3
                                                })}
                                            </BoxLabel>
                                            <BoxContent>
                                                <FormInput fieldName='item_3' />
                                            </BoxContent>
                                        </BoxRow>
                                        <BoxRow>
                                            <BoxLabel minWidth="70px">
                                                {Validator.renderMessage(t("package_tracking.item"), {
                                                    0: 4
                                                })}
                                            </BoxLabel>
                                            <BoxContent>
                                                <FormInput fieldName='item_4' />
                                            </BoxContent>
                                        </BoxRow>
                                        <BoxRow>
                                            <BoxLabel minWidth="70px">
                                                {Validator.renderMessage(t("package_tracking.item"), {
                                                    0: 5
                                                })}
                                            </BoxLabel>
                                            <BoxContent>
                                                <FormInput fieldName='item_5' />
                                            </BoxContent>
                                        </BoxRow>
                                        <BoxRow style={{
                                            justifyContent: "center"
                                        }}>
                                            <FormCaptcha validate={[Validator.required]} fieldName='captcha' />
                                        </BoxRow>
                                    </BoxBody>
                                </Box>
                            </WrapContentBody>
                        </BoxContentPopup>
                    </form>
                </CustomFormProvider> : <PackageTrackingSearch dataRes={dataRes} data={dataSearch} handleBtnBack={() => handleBtnBack()} showVisible={props?.showVisible} title={props?.title} />
            }
        </>
    )
}

export default PackageTracking