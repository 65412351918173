import React from 'react';

function IconConnectorStep({ color = 'white' }) {
    return (
        <svg
            width="90"
            height="4"
            viewBox="0 0 90 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 2H453" stroke="#83378A" strokeLinecap="round" strokeWidth="3" strokeLinejoin="round" strokeDasharray="5 10" />
        </svg>
    );
}

export default IconConnectorStep;
