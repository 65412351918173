import React from 'react'
import classes from './Loading.module.scss';

function Loading(props) {
    // color : | black | hippie blue | default |
    const {
        color = 'default',
    } = props;

    const getColor = (_color) => {
        switch (_color) {
            case 'black':
                return 'black';
            case 'hippie_blue':
                return '#83378A';
            default:
                return '#fed';
        }
    };

    const style = {
        '--bg-color': getColor(color)
    };

    return (
        <div className={`${classes['lds-spinner']} lds-spinner `} style={style}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>)
}

export default Loading