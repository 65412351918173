import React, { useEffect } from 'react'
import { ArrowOutward, FaxOutlined, Phone } from '@mui/icons-material';
import IconEmail from 'assets/icon/IconEmail';
import headerWorld1 from 'assets/img/header-world-1.png';
import headerWorld2 from 'assets/img/header-world-2.png';
import useTrans from 'hooks/use-trans';
import EventRegister, { EVENT_SHOW_FAQ_POPUP, EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_USER_INQUIRY, EVENT_SHOW_POPUP_USER_LOGIN, EVENT_SHOW_POPUP_USER_REGISTER, EVENT_SHOW_SERVICE_DETAIL_POPUP, EVENT_SHOW_POPUP_ESTIMATE_FARE, G0100 } from 'utils/EventRegister';
import styles from "./Home.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import actionHome from 'redux/home/action';
import { convertNumberToString } from 'utils/String';
import Constants from 'utils/Constants';
import useCustomState from 'hooks/use-custom-state';
import i18n from "i18nextInit";
import IconBook from 'assets/icon/IconBook';
import useRouter from 'hooks/use-router';
import actionFareCalculation from 'redux/freight-calculation/action';

const HomeContainer = () => {
    const { trans, t } = useTrans();
    const dispatch = useDispatch();
    const [lang, setLang] = useCustomState(localStorage.getItem("i18nextLng") ?? Constants.T001.JP.CODE)
    const [optionLanguages, setOptionLanguages] = useCustomState([]);
    const { services, dataOrder, termOfUse, policy } = useSelector(state => state.home);

    const handlePopupLogin = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_LOGIN,
            open: true,
            payload: {
                title: trans("Login.title"),
                customStyle: {
                    maxHeight: 'calc(100vh - 90px - 50px)'
                }
            }
        })
    }

    const handleOpenPackageTracking = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: G0100,
            open: true,
            payload: {
                showHeader: false,
                customStyle: {
                    padding: 0,
                    borderTop: 0
                },
                option: {
                    typePopup: G0100
                }
            }
        })
    }

    const handlePopupInquiry = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_INQUIRY,
            open: true,
            payload: {
                title: trans("Inquiry.inquiry_user"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    const handleEstimateFare = () => {
        dispatch({
            type: actionFareCalculation.REMOVE_FARE_CALC_MANAGEMENT_CALCULATE
        })
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_ESTIMATE_FARE,
            open: true,
            payload: {
                title: trans("home.estimated_fare"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)',
                },
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: actionHome.GET_LIST_HEX_SERVICE
        })
        dispatch({
            type: actionHome.GET_TERM_OF_USE_HOME
        })
        dispatch({
            type: actionHome.GET_PRIVATE_POLICY_HOME
        })
    }, [lang])

    const handleShowServiceDetail = (_service) => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: _service?.title,
                content: _service?.content
            }
        })
    }

    const handleOpenTermOfUse = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: trans("faq_management.term_of_service"),
                content: termOfUse?.content
            }
        })
    }
    const handleOpenPolicy = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_SERVICE_DETAIL_POPUP,
            open: true,
            payload: {
                title: trans("faq_management.private_policy"),
                content: policy?.content
            }
        })
    }

    const handleOpenFaq = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_FAQ_POPUP,
            open: true,
            payload: {
                title: "FAQ",
            }
        })
    }


    useEffect(() => {
        let data = []
        Object.keys(Constants.T001).forEach(key => {
            data.push({
                key: Constants.T001[key].CODE,
                value: Constants.T001[key].CODE,
                label: Constants.T001[key].NAME,
            })
        });
        setOptionLanguages(data)
        i18n.on("languageChanged", (val) => {
            setLang(val)
        })
        return () => {
            i18n.off("languageChanged")
        }
    }, [])

    const handlePopupRegister = () => {
        EventRegister.emit(EVENT_SHOW_POPUP, {
            type: EVENT_SHOW_POPUP_USER_REGISTER,
            open: true,
            payload: {
                title: trans("Inquiry.sign_up"),
                customStyle: {
                    maxHeight: 'calc(100vh - 150px)'
                },
            }
        })
    }

    return (
        <div className="container-xl" style={{ padding: 0 }} >
            {handlePopupLogin()}
        </div >
    )
}

export default HomeContainer;