import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/holon/action';
import { default as AppActions } from 'redux/app/action';
import Constants from 'utils/Constants';
import { useStateMachine } from "little-state-machine";
import RequiredLabel from '../../common/RequiredLabel';
import { persistAction } from 'utils/Helper';
import EventRegister, { EVENT_SHOW_POPUP, EVENT_SHOW_POPUP_NOTIFICATION_MESSAGE, EVENT_SHOW_POPUP_USER_REGISTER_2 } from 'utils/EventRegister';
import StepperComponent from '../../common/Stepper';
import Field from '../../form/Field';

import styles from "./Register.module.scss";
import useTrans from 'hooks/use-trans';
import CustomTooltip from '../../common/tooltip/CustomTooltip';
import moment from 'moment';
import ElementType from 'utils/ElementType';
import FormInput from 'shared/components/common/form/home/FormInput';
import Validator from 'utils/Validator';
import Loading from 'shared/components/form-input/button/loading/Loading';

const UserRegisterPopup = () => {
    const dispatch = useDispatch();
    const [honninTemplate, setHonninTemplate] = useState([]);
    const [userTemplate, setUserTemplate] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const { actions, state } = useStateMachine({ persistAction });
    const { trans } = useTrans();

    const methods = useForm({
        defaultValues: state?.data
    });

    const { register, handleSubmit, setError, setValue, getValues } = methods;
    useEffect(() => {
        dispatch({
            type: AppActions.SHOW_LOADING
        });
        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.HONNIN_TEMPLATE,
            callback: (data) => {
                setHonninTemplate(data);
                actions.persistAction({
                    honnin: data
                });
            }
        })
        dispatch({
            type: Actions.GET_HOLON_TEMPLATES_BY_MASTER_CODES,
            payload: Constants.HOLON_MASTER_CODE.USER_TEMPLATE,
            callback: (data) => {
                setUserTemplate(data);
                actions.persistAction({
                    user: data
                });
            }
        });

        dispatch({
            type: AppActions.HIDE_LOADING
        });
        return () => {
            dispatch({
                type: Actions.CLEAR_HOLON_TEMPLATE,
            });
        }
    }, []);

    const onSubmit = (data) => {
        setDisabled(true);
        let submitData = {};
        const emailKey = 'email';
        Object.keys(data)?.map(key => {
            if (typeof data[key] == 'object') {
                submitData[key] = moment(data[key]).format('DD/MM/yyyy');
            } else {
                submitData[key] = data[key]
            }
        });
        dispatch({
            type: Actions.GET_HOLON_INSTANCE_BY_ATTRIBUTE_VALUE,
            value: data[emailKey],
            templateId: userTemplate[0]?.id,
            callback: (data) => {
                setDisabled(false);
                if (data && data?.id) {
                    setError(emailKey, {
                        type: 'custom',
                        message: trans('E0008')
                    })
                } else {
                    actions.persistAction({ data: submitData });
                    EventRegister.emit(EVENT_SHOW_POPUP, {
                        type: EVENT_SHOW_POPUP_USER_REGISTER_2,
                        open: true,
                        payload: {
                            title: trans("Inquiry.sign_up"),
                            customStyle: {
                                maxHeight: 'calc(100vh - 150px)'
                            },
                        }
                    })
                }
            },
            fallback: () => {
                setDisabled(false);
                setError(emailKey, {
                    type: 'custom',
                    message: trans('E0008')
                })
            }
        })
    }

    const renderField = (field, index) => {
        switch (field?.elementType) {
            case ElementType.PASSWORD:
                return <React.Fragment key={index}>
                    <div className={`${styles['input-wrap']} d-flex`} >
                        <div className={`${styles['input-label']} col-5 d-flex justify-content-between pe-3`}>
                            <span className='d-flex'>
                                {field?.label}
                                {
                                    field?.explain
                                    && <CustomTooltip
                                        text="ｉ"
                                        textTooltip={field?.explain}
                                        style={{
                                            color: "#fff",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: '#83378A',
                                            minWidth: '20px',
                                            margin: '0 5px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            fontSize: '16px',
                                            letterSpacing: 0,
                                        }}
                                    />
                                }
                            </span>
                            {
                                field.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                            }
                        </div>
                        <div className='col h-100'>
                            <Field
                                field={field}
                                register={register}
                                data={state[field?.key] ? state[field?.key] : undefined}
                                setValue={setValue}
                            />
                        </div>
                    </div>
                    <div className={`${styles['input-wrap']} d-flex`} key={field?.id}>
                        <div className={`${styles['input-label']} col-5 d-flex justify-content-between pe-3`}>
                            <div className={'d-flex'}>
                                <span>{trans('T011.confirm_password.label')}</span>
                                <CustomTooltip
                                    text="ｉ"
                                    textTooltip={field?.explain}
                                    style={{
                                        color: "#fff",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#83378A',
                                        minWidth: '20px',
                                        margin: '0 5px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        fontSize: '16px',
                                        letterSpacing: 0,
                                    }}
                                />
                            </div>
                            <RequiredLabel />
                        </div>
                        <div className='col h-100'>
                            <FormInput
                                isShowRevealPasswordButton={true}
                                fieldName={'password_confirm'}
                                register={register}
                                validate={[Validator.required, Validator.confirmPassword(methods.watch(`${field?.key}`))]}
                                placeholder={trans('T011.confirm_password.label')}
                                type="password"
                            />
                        </div>
                    </div>
                </React.Fragment>
            default:
                return <div className={`${styles['input-wrap']} d-flex`} key={index}>
                    <div className={`${styles['input-label']} col-5 d-flex justify-content-between pe-3`}>
                        <span className='d-flex'>
                            {field?.label}
                            {
                                field?.explain
                                && <CustomTooltip
                                    text="ｉ"
                                    textTooltip={field?.explain}
                                    style={{
                                        color: "#fff",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#83378A',
                                        minWidth: '20px',
                                        margin: '0 5px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        fontSize: '16px',
                                        letterSpacing: 0,
                                    }}
                                />
                            }
                        </span>
                        {
                            field.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                        }
                    </div>
                    <div className='col h-100'>
                        <Field
                            field={field}
                            register={register}
                            data={state?.data && state?.data[field?.key] ? state?.data[field?.key] : undefined}
                            setValue={setValue}
                        />
                    </div>
                </div>
        }
    }

    return <div className={styles['register-container']}>
        <div className='mb-3 d-center'>
            <StepperComponent
                steps={Constants.REGISTER_STEP}
                diameter={120}
            />
        </div>
        <FormProvider {...methods}>
            <React.Fragment>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles['notify-header']}>
                        {trans("register_form.step1.title")}
                    </div>
                    {
                        userTemplate && userTemplate?.map(template => template?.attributes?.map((field, index) => {
                            return field && (field?.elementType !== ElementType.AUTO && !Constants.BLOCKED_FIELD_REGISTER.includes(field?.key) && !field?.key?.startsWith("setting")) && renderField(field, index)
                        }))
                    }
                    {
                        honninTemplate && honninTemplate?.map(template => template?.attributes?.map((field, index) => {
                            return field && field?.elementType !== ElementType.AUTO && !Constants.BLOCKED_FIELD_REGISTER.includes(field?.key) && !field?.key?.startsWith("setting") &&
                                <div className={`${styles['input-wrap']} d-flex`} key={index * 100}>
                                    <div className={`${styles['input-label']} col-5 d-flex justify-content-between pe-3`}>
                                        <span className='d-flex'>
                                            {field?.label}
                                            {
                                                field?.explain
                                                && <CustomTooltip
                                                    text="ｉ"
                                                    textTooltip={field?.explain}
                                                    style={{
                                                        color: "#fff",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        background: '#83378A',
                                                        minWidth: '20px',
                                                        margin: '0 5px',
                                                        height: '20px',
                                                        borderRadius: '50%',
                                                        fontSize: '16px',
                                                        letterSpacing: 0,
                                                    }}
                                                />
                                            }
                                        </span>
                                        {
                                            field.isRequired == Constants.IS_REQUIRED ? <RequiredLabel /> : null
                                        }
                                    </div>
                                    <div className='col h-100'>
                                        <Field
                                            field={field}
                                            register={register}
                                            data={getValues(field?.key)}
                                            setValue={setValue}
                                        />
                                    </div>
                                </div>

                        }))
                    }
                    {
                        honninTemplate?.length > 0 || userTemplate?.length > 0 ?
                            <div className='text-center'>
                                <button disabled={disabled} className={`${styles['btn-submit']} btn btn-dark mx-auto`}>
                                    <span className={disabled ? styles['disable-btn'] : ''} >{trans("register_form.button.submit")}</span>
                                    {disabled ? <div className={styles['centered-content']}><Loading /> </div> : ''}
                                </button>
                            </div>
                            : null
                    }
                </form>
            </React.Fragment>
        </FormProvider>
    </div>
}

export default UserRegisterPopup