import React from 'react'

function IconStatsAlerts() {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height={40}
                fill="none"
            >
                <rect width={40} height={40} fill="#83378A" rx={10} />
                <path
                    fill="#fff"
                    d="M21.078 23.98a1.197 1.197 0 1 0 0 2.395 1.197 1.197 0 0 0 0-2.395Z"
                />
                <path
                    fill="#fff"
                    d="M30.882 25.949 22.84 12.017A2.04 2.04 0 0 0 21.077 11c-.725 0-1.4.39-1.762 1.017l-8.043 13.931a2.04 2.04 0 0 0 0 2.035A2.04 2.04 0 0 0 13.034 29H29.12c.725 0 1.4-.39 1.762-1.017a2.04 2.04 0 0 0 0-2.034Zm-1.599.995a.654.654 0 0 1-.565.325H13.436a.652.652 0 0 1-.565-.977l7.641-13.236a.653.653 0 0 1 1.13 0l7.641 13.236a.654.654 0 0 1 0 .652Z"
                />
                <path
                    fill="#fff"
                    d="M21.078 15.935c-.661 0-1.198.535-1.198 1.196l.46 5.412a.737.737 0 1 0 1.475 0l.459-5.412c0-.66-.536-1.196-1.197-1.196Z"
                />
            </svg>
        </>
    )
}

export default IconStatsAlerts