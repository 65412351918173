import React from 'react'
import classes from './CustomHeader.module.scss';
import Button from 'shared/components/form-input/button/Button';
import IconLeftArrow from 'assets/icon/IconLeftArrow';

function CustomHeaderPopup(props) {
    const { showVisible, onClickBtn, payload, ActionSection, title, isUseBtnBack = true,
        useForm = false,
        isUseActionSectionTop = true
    } = props;
    return (
        <>
            <div className={classes['CustomHeaderPopup']}>
                <div className={`${classes['HeaderLeft']} ${!useForm ? classes['HeaderLeftV2'] : ''}`}>
                    {
                        isUseBtnBack && <Button onClick={() => {
                            if (onClickBtn) {
                                onClickBtn && onClickBtn()
                            } else {
                                showVisible && showVisible(false);
                            }
                        }} startIcon={<IconLeftArrow color="white" />} />
                    }
                    <p>{title ?? payload?.title}</p>
                </div>
                {
                    isUseActionSectionTop && <div className={classes['Custom-Header-Action']}>
                        {ActionSection}
                    </div>
                }
            </div>
        </>
    )
}

export default CustomHeaderPopup