import React from 'react';
import { useEffect } from 'react';
import { handleScroll } from 'utils/Helper';
import styles from "./Loader.module.scss";

function Loader (props) {
    const { loading } = props;
    useEffect(() => {
        handleScroll(loading,'no-scroll')
    }, []);

    return (
        <div className={styles['loading-wrapper']}>
            <div className={styles['loading-icon']}>
                CBE...
            </div>
        </div>
    )
}
export default Loader;