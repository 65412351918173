import React, { useEffect } from 'react'
import classes from './ListProductPopup.module.scss';
import Loading from 'shared/components/form-input/button/loading/Loading';
import { convertNumberToString } from 'utils/String';
import Button from 'shared/components/form-input/button/Button';
import useTrans from 'hooks/use-trans';
import IconPlus from 'assets/icon/IconPlus';

function TableLeft(props) {
    const { isGetFromProductMaster, errorMessage, currentList, loading, handleAdd } = props;
    let ignoreScrollEvents = false;
    const { t } = useTrans();

    const syncScroll = (...selectors) => {
        const wrapper = document.getElementById('product-left');
        if (wrapper && selectors.length > 1) {
            wrapper.addEventListener(
                'scroll',
                ({ target }) => {
                    if (target.classList.contains(selectors[0])) {
                        const ignore = ignoreScrollEvents;
                        ignoreScrollEvents = false;
                        if (ignore) {
                            return;
                        }
                        ignoreScrollEvents = true;
                        const { scrollLeft } = target;
                        selectors.forEach((selector) => {
                            if (wrapper.querySelector(`.${selector}`)) {
                                wrapper.querySelector(`.${selector}`).scrollLeft = scrollLeft;
                            }
                        });
                    }
                },
                true,
            );
        }
    };

    useEffect(() => {
        const executeSyncScroll = () => {
            syncScroll(
                '_custom_scroll_footer',
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_total',
            );
            syncScroll(
                '_custom_scroll_body',
                '_custom_scroll_header',
                '_custom_scroll_footer',
                '_custom_scroll_total',
            );
        };
        executeSyncScroll();
    }, []);

    return (
        <>
            <div style={{
                borderRadius: '10px 10px 0px 0px',
            }}>
                <div id='product-left' className={classes['custom-table']}>
                    <div className={`_custom_scroll_header ${classes['header']} ${classes['header-left']}`}>
                        <div style={{ minWidth: 400, width: '100%', maxWidth: '100%' }} className={classes['content-with']}>
                            {t("product.product_name")}
                        </div>
                        <div style={{ minWidth: 120, width: 120, maxWidth: 120 }} className={classes['content-with']}>
                            {t("product.gs1_code")}
                        </div>
                        {
                            !isGetFromProductMaster && <div style={{ minWidth: 70, width: 70, maxWidth: 70, display: 'flex', justifyContent: 'flex-end', }} className={classes['content-with']}>
                                {t("product.quantity")}
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 70, width: 70, maxWidth: 70 }} className={classes['content-with']}>
                            {t("product.product_price")}
                        </div>
                        <div style={{
                            minWidth: 30, width: 30, maxWidth: 30, position: 'sticky',
                            right: 0,
                            background: '#83378A'
                        }} className={`${classes['content-with']} ${classes['content-add']}`}></div>
                    </div>
                    {
                        loading && <div className={classes['custom-loading']}>
                            <Loading />
                        </div>
                    }
                    <div className={`_custom_scroll_body ${classes['body']}`}>
                        {
                            currentList?.map((x, index) => {
                                return <div className={classes['custom-row']} key={index}>
                                    <div title={x?.name} style={{ minWidth: 400, width: '100%', maxWidth: '100%' }} className={classes['content-with']}>
                                        {x?.name}
                                    </div>
                                    <div style={{ minWidth: 120, width: 120, maxWidth: 120 }} className={classes['content-with']}>
                                        {x?.janCode}
                                    </div>
                                    {
                                        !isGetFromProductMaster && <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 70, width: 70, maxWidth: 70 }} className={classes['content-with']}>
                                            {convertNumberToString(x?.quantity)}
                                        </div>
                                    }
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 70, width: 70, maxWidth: 70 }} className={classes['content-with']}>
                                        {convertNumberToString(x?.price)}
                                    </div>
                                    <div style={{
                                        minWidth: 30, width: 30, maxWidth: 30,
                                        position: 'sticky',
                                        right: '-1px',
                                    }} className={`${classes['content-with']} ${classes['content-add']}`}>
                                        <Button disabled={errorMessage ? true : false} className='btn-add' backgroundTransparent size='small' onClick={() => handleAdd(x)} color='white' startIcon={<IconPlus />} />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`_custom_scroll_footer ${classes['footer-left']}`}>
                        <div style={{ minWidth: 400, width: '100%', maxWidth: '100%' }} className={classes['content-with']}>
                            {t("product.product_name")}
                        </div>
                        <div style={{ minWidth: 120, width: 120, maxWidth: 120 }} className={classes['content-with']}>
                            {t("product.gs1_code")}
                        </div>
                        {
                            !isGetFromProductMaster && <div style={{ minWidth: 70, width: 70, maxWidth: 70 }} className={classes['content-with']}>
                                {t("product.quantity")}
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 70, width: 70, maxWidth: 70 }} className={classes['content-with']}>
                            {t("product.product_price")}
                        </div>
                        <div style={{
                            minWidth: 30, width: 30, maxWidth: 30, position: 'sticky',
                            right: 0,
                        }} className={classes['content-with']}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableLeft